<div class="bg-gray-200">
    <div class="bg-gray-100 pt-32 lg:pt-52 pb-14 mx-auto">
        <div class="max-w-5xl mx-auto px-6 lg:px-8">
            <h1 @slideUp class="text-3xl font-bold text-gray-800 text-center mb-8">
                За Нас | Алое вера гел - Aloe vera gel
            </h1>
            <p class="text-lg text-gray-700 leading-relaxed text-center mb-8">
                Добредојдовте на <strong>AloeVeraGel.mk</strong>! Вашата дестинација за најдобрите производи од алое
                вера! Овде ќе најдете алое вера пијалоци, гелови и решенија за здравје и убавина од
                <strong>Forever Living Products</strong>, глобална компанија која ги комбинира најдобрите природни
                состојки со
                најсовремените технологии за создавање на висококвалитетни производи.
            </p>

            <div class="bg-white shadow-md rounded-lg p-6 mb-8">
                <h2 class="text-2xl font-semibold text-gray-800 mb-4">
                    За Forever Living Products
                </h2>
                <p class="text-gray-700 leading-relaxed mb-4">
                    Основана во 1978 година од <strong>Рекс Моан</strong>, Forever Living Products е повеќе од обична
                    компанија – тоа е семејство кое
                    помага милиони луѓе низ целиот свет да изгледаат и да се чувствуваат подобро. Со присуство во над
                    160 земји, Форевер е лидер во
                    производството на алое вера производи.
                </p>
                <p class="text-gray-700 leading-relaxed">
                    Компанијата го контролира секој чекор од процесот – од одгледувањето на растението, преку
                    производството, до дистрибуцијата на производите директно до вас.
                </p>
            </div>

            <div class="bg-white shadow-md rounded-lg p-6 mb-8">
                <h2 class="text-2xl font-semibold text-gray-800 mb-4">
                    Што Нудиме
                </h2>
                <ul class="list-disc list-inside text-gray-700 leading-relaxed">
                    <li><strong>Најдобрите алое вера производи</strong> за вашето здравје и убавина.</li>
                    <li><strong>Иновативни решенија</strong> кои гарантираат врвен квалитет.</li>
                    <li><strong>Глобална поддршка</strong> од заедницата на Форевер.</li>
                </ul>
            </div>

            <div class="bg-white shadow-md rounded-lg p-6 mb-8">
                <h2 class="text-2xl font-semibold text-gray-800 mb-4">
                    Бизнис Можност
                </h2>
                <p class="text-gray-700 leading-relaxed">
                    Со Forever Living Products, имате можност да изградите кариера според вашите соништа. Програмите за
                    стимулирање, патувања, бонуси и обуки се само дел од привилегиите кои ве очекуваат.
                </p>
            </div>

            <div class="text-center">
                <h3 class="text-xl font-semibold text-gray-800 mb-4">Зошто AloeVeraGel.mk?</h3>
                <p class="text-gray-700 leading-relaxed mb-6">
                    Вашето здравје и благосостојба се наш главен приоритет. Станете дел од семејството на <strong>Forever
                    Living Products</strong>
                    и искусете го најдоброто од светот на алое вера.
                </p>
                <div class="lg:w-60 mx-auto">
                    <app-primary-button
                            [value]="'Контактирај нè денес'"
                            (click)="goToUrl(router, 'contact-us')"
                    ></app-primary-button>
                </div>
            </div>
        </div>
        <app-text
                class="pt-10 lg:pt-14 text-gray-800 font-medium text-base lg:text-2xl leading-loose uppercase text-center"
                value="СЛЕДИ Нè"/>
        <!-- Social Media Icons -->
        <div class="flex justify-center relative space-x-3">
            @for (iconLink of iconLinks; track iconLink) {
                <app-secondary-button [class]="'fill-black'"
                                      [icon]="iconLink.icon"
                                      (click)="openExternalLink(iconLink.link)"></app-secondary-button>
            }
        </div>
    </div>
</div>
<app-footer></app-footer>



