import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ButtonModule} from "../button/button.module";
import {HeaderComponent} from "./header.component";
import {LinkModule} from "../link/link.module";
import {LoginComponent} from "../login/login.component";
import {PrimaryButtonComponent} from "../button/primary/primary.button.component";
import {CartOverviewModule} from "../cart-overview/cart-overview.module";
import {SearchOverviewModule} from "../search-overview/search-overview.module";
import {MusicPlayerModule} from "../music-player/music-player.module";
import {InfoBannerComponent} from "../info-banner/info-banner.component";


@NgModule({
    declarations: [
        HeaderComponent
    ],
    exports: [
        HeaderComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        LinkModule,
        NgOptimizedImage,
        LoginComponent,
        PrimaryButtonComponent,
        CartOverviewModule,
        SearchOverviewModule,
        MusicPlayerModule,
        InfoBannerComponent,
    ]
})
export class HeaderModule {
}
