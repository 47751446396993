import {BehaviorSubject, tap} from "rxjs";
import {Page} from "../../models/paginator";
import {HttpClient} from "@angular/common/http";
import {Search} from "../../models/filters";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";

export function getEntityPage<T>(
    pageSubject$: BehaviorSubject<Page<T>>,
    httpClient: HttpClient,
    url: string
): void {
    httpClient
        .get<Page<T>>(url)
        .pipe(tap((entityPage: Page<T>) => pageSubject$.next(entityPage)))
        .subscribe();
}

export function getSearchedEntity<T>(
    search: Search,
    pageSubject$: BehaviorSubject<Page<T>>,
    httpClient: HttpClient,
    url: string
): void {
    httpClient
        .post<Page<T>>(url, search.search)
        .pipe(tap((entityPage: Page<T>) => pageSubject$.next(entityPage)))
        .subscribe();
}

export function goToUrl(router: Router, url: string): void {
    router.navigateByUrl(url).then(() => {
        window.scrollTo(0, 0);
    })
}

export function formatDate(datePipe: DatePipe, date: string): string {
    return datePipe.transform(date, 'd MMM yyyy', undefined, 'mk') ?? '';
}

export function setCanonicalUrl(url: string) {
    const existingCanonical = document.querySelector('link[rel="canonical"]');
    if (existingCanonical) {
        existingCanonical.remove();
    }

    const link: HTMLLinkElement = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', url);
    document.head.appendChild(link);
}

export function truncateString(str: string, maxLength: number): string {
    if (str.length <= maxLength) {
        return str;
    }
    return str.slice(0, maxLength) + '...';
}