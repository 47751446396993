import {Component} from '@angular/core';
import {ProductCategory} from "../../models/productCategory";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {environment} from "../../environments/environment";

@Component({
    selector: 'app-product-category-list',
    templateUrl: './product-category-list.component.html',
    styleUrls: ['./product-category-list.component.css'],
    animations: [
        trigger('slideInFromBottom', [
            state('hidden', style({
                transform: 'translateY(200%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateY(0)',
                opacity: 1
            })),
            transition('hidden <=> visible', animate('0.7s')),
        ])]
})
export class ProductCategoryListComponent {

    productCategories: ProductCategory[] = [
        {
            image: 'https://aloeveragel.mk/assets/images/aloe-vera-pijaloci/aloe-vera-gel-forever.webp',
            link: {
                text: 'АЛОЕ ВЕРА ПИЈАЛОЦИ',
                route: environment.navigation.shop + '/aloe-vera-pijaloci'
            }
        },
        {
            image: 'https://aloeveragel.mk/assets/images/pcelni-proizvodi/pcelen-polen.webp',
            link: {
                text: 'ПЧЕЛНИ ПРОИЗВОДИ',
                route: environment.navigation.shop + '/pcelni-proizvodi'
            }
        },
        {
            image: 'https://aloeveragel.mk/assets/images/dodatoci-vo-ishrana/vitamini-za-deca.webp',
            link: {
                text: 'ДОДАТОЦИ ВО ИСХРАНА',
                route: environment.navigation.shop + '/dodatoci-vo-ishrana'
            }
        },
        {
            image: 'https://aloeveragel.mk/assets/images/licna-higiena/aloe-tus-gel.webp',
            link: {
                text: 'ЛИЧНА НЕГА',
                route: environment.navigation.shop + '/licna-nega'
            }
        },
        {
            image: 'https://aloeveragel.mk/assets/images/nega-za-lice/utrinska-krema-za-okolu-oci.webp',
            link: {
                text: 'НЕГА ЗА ЛИЦЕ',
                route: environment.navigation.shop + '/nega-za-lice'
            }
        },
        {
            image: 'https://aloeveragel.mk/assets/images/etericni-masla/etericni-masla-lavanda.webp',
            link: {
                text: 'ЕТЕРИЧНИ МАСЛА',
                route: environment.navigation.shop + '/etericni-masla'
            }
        },
        {
            image: 'https://aloeveragel.mk/assets/images/nega-za-telo/aloe-losion-za-telo.webp',
            link: {
                text: 'НЕГА ЗА ТЕЛО',
                route: environment.navigation.shop + '/nega-za-telo'
            }
        },
        {
            image: 'https://aloeveragel.mk/assets/images/kontrola-na-tezina/forever-lean.webp',
            link: {
                text: 'КОНТРОЛА НА ТЕЛЕСНА ТЕЖИНА',
                route: environment.navigation.shop + '/kontrola-na-telesna-tezina'
            }
        }
    ]

}
