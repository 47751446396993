import {Component, OnInit} from '@angular/core';
import {Blog} from "../../../../models/blog";
import {Router} from "@angular/router";
import {BlogService} from "../service/blog.service";
import {DatePipe} from "@angular/common";
import {formatDate, goToUrl, truncateString} from "../../../../services/util/util";
import {Title} from "@angular/platform-browser";
import {animate, style, transition, trigger} from "@angular/animations";
import {environment} from "../../../../environments/environment";
import {AuthService} from "@auth0/auth0-angular";
import {Carousel, initTE} from "tw-elements";

@Component({
    selector: 'app-blog-list',
    templateUrl: './blog-list.component.html',
    styleUrl: './blog-list.component.css',
    animations: [
        trigger('slideDown', [
            transition(':enter', [
                style({transform: 'translateY(-50%)', opacity: 0}),
                animate('0.7s')
            ])
        ])
    ]
})
export class BlogListComponent implements OnInit {

    pageTitle: string = 'Блог';

    blogs: Blog[] = [];

    constructor(protected readonly router: Router,
                private blogService: BlogService,
                protected datePipe: DatePipe,
                private titleService: Title,
                public auth: AuthService
    ) {
    }

    ngOnInit(): void {
        initTE({Carousel});
        this.titleService.setTitle(this.pageTitle);
        this.blogService.getAllBlogs().subscribe((blogs) => this.blogs = blogs);
    }

    protected readonly goToUrl = goToUrl;
    protected readonly environment = environment;
    protected readonly formatDate = formatDate;
    protected readonly truncateString = truncateString;
}