import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AboutUsComponent} from "./modules/about-us/about-us.component";
import {ShopComponent} from "./modules/shop/shop.component";
import {CartComponent} from "./modules/cart/cart.component";
import {CheckoutComponent} from "./modules/checkout/checkout.component";
import {HomeComponent} from "./modules/home/home.component";
import {ProfileComponent} from "./modules/profile/profile.component";
import {ContactUsComponent} from "./modules/contact-us/contact-us.component";
import {AdminPanelComponent} from "./modules/admin-panel/admin-panel.component";
import {ProductPageComponent} from "./modules/shop/components/product-page/product-page.component";
import {StatusPageComponent} from "./components/status-page/status-page.component";
import {ProductManageComponent} from "./modules/shop/components/product-manage/product-manage.component";
import {DiscountManageComponent} from "./components/discount-manage/discount-manage.component";
import {EmailMarketingComponent} from "./modules/admin-panel/components/email-marketing/email-marketing.component";
import {BlogDetailComponent} from "./modules/blog/components/blog-detail/blog-detail.component";
import {BlogManageComponent} from "./modules/blog/components/blog-manage/blog-manage.component";
import {BlogListComponent} from "./modules/blog/components/blog-list/blog-list.component";
import {BrandAmbassadorComponent} from "./modules/brand-ambassador/brand-ambassador.component";

export const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            title: 'Алое вера гел - Природна нега со алое вера гел и производи за здравје и убавина!',
            metaDescription: 'Алое вера гел – Специјализирани алое вера производи за природна нега на тело и лице, контрола на тежина и здрав живот. Откријте висококвалитетни алое вера гелови и производи со природни состојки за секојдневна убавина и здравје.',
            canonicalUrl: '/'
        }
    },
    {
        path: 'about-us',
        component: AboutUsComponent,
        data: {
            title: 'За Нас',
            metaDescription: 'Откријте го најдоброто од алое вера! AloeVeraGel.mk нуди производи од Forever Living Products за здравје, убавина, и бизнис можности.',
            canonicalUrl: '/about-us'
        }
    },
    {
        path: 'shop',
        component: ShopComponent,
        data: {
            title: 'Продавница',
            metaDescription: 'Истражете ја нашата продавница со широк избор на алое вера производи и природни додатоци за здравје и убавина. Од алое вера пијалоци до производи за нега на тело, најдете сè што ви треба за природна нега и благосостојба.',
            canonicalUrl: '/shop'
        }
    },
    {
        path: 'shop/aloe-vera-pijaloci',
        component: ShopComponent,
        data: {
            title: 'Алое вера пијалоци',
            metaDescription: 'Откријте го нашиот избор на алое вера пијалоци за поддршка на вашето здравје и благосостојба. Идеални за хидратација и зајакнување на имунитетот.',
            canonicalUrl: '/shop/aloe-vera-pijaloci'
        }
    },
    {
        path: 'shop/pcelni-proizvodi',
        component: ShopComponent,
        data: {
            title: 'Пчелни производи',
            metaDescription: 'Изберете меѓу нашите природни пчелни производи богати со хранливи материи за поддршка на имунитетот и природно здравје. Од мед до прополис, секој производ е внимателно избран.',
            canonicalUrl: '/shop/pcelni-proizvodi'
        }
    },
    {
        path: 'shop/dodatoci-vo-ishrana',
        component: ShopComponent,
        data: {
            title: 'Додатоци во исхрана',
            metaDescription: 'Нашите додатоци во исхрана се направени да ги задоволат потребите на вашето тело со есенцијални витамини и минерали. Откријте го идеалниот додаток за вашиот животен стил.',
            canonicalUrl: '/shop/dodatoci-vo-ishrana'
        }
    },
    {
        path: 'shop/licna-nega',
        component: ShopComponent,
        data: {
            title: 'Лична нега',
            metaDescription: 'Грижете се за вашето тело со производи за лична нега кои комбинираат природни состојки за свежина и хидратација. Совршени за секојдневна употреба и здрав сјај.',
            canonicalUrl: '/shop/licna-nega'
        }
    },
    {
        path: 'shop/nega-za-lice',
        component: ShopComponent,
        data: {
            title: 'Нега за лице',
            metaDescription: 'Погрижете се за вашето лице со нашите специјално дизајнирани производи за нега на тело. Обновете ја кожата и додадете природен сјај со нашиот избор на производи.',
            canonicalUrl: '/shop/nega-za-lice'
        }
    },
    {
        path: 'shop/etericni-masla',
        component: ShopComponent,
        data: {
            title: 'Етерични масла',
            metaDescription: 'Откријте ја моќта на етеричните масла за природна релаксација и баланс. Нашите масла се од висок квалитет и идеални за масажа или ароматерапија.',
            canonicalUrl: '/shop/etericni-masla'
        }
    },
    {
        path: 'shop/nega-za-telo',
        component: ShopComponent,
        data: {
            title: 'Нега за тело',
            metaDescription: 'Пронајдете ги најдобрите производи за нега на тело кои нудат длабока хидратација и заштита. Совршени за сите типови кожа и секојдневна нега.',
            canonicalUrl: '/shop/nega-za-telo'
        }
    },
    {
        path: 'shop/kontrola-na-telesna-tezina',
        component: ShopComponent,
        data: {
            title: 'Контрола на телесната тежина',
            metaDescription: 'Нашите производи за контрола на телесната тежина се внимателно избрани за да ви помогнат на патот кон здравјето и балансот. Откријте го нашиот избор за активен животен стил.',
            canonicalUrl: '/shop/kontrola-na-telesna-tezina'
        }
    },
    {
        path: 'shop/product/discount/add',
        component: DiscountManageComponent
    },
    {
        path: 'shop/product/discount/:id/edit',
        component: DiscountManageComponent
    },
    {
        path: 'shop/product/add',
        component: ProductManageComponent
    },
    {
        path: 'shop/product/:slugAndId',
        component: ProductPageComponent
    },
    {
        path: 'shop/product/:id/edit',
        component: ProductManageComponent
    },
    {
        path: 'blogs',
        component: BlogListComponent,
        data: {
            title: 'Блог',
            metaDescription: 'Прочитајте ги најновите објави на Непокор.мк.',
            canonicalUrl: '/blogs'
        }
    },
    {
        path: 'blogs/add',
        component: BlogManageComponent
    },
    {
        path: 'blogs/:slugAndId',
        component: BlogDetailComponent
    },
    {
        path: 'blogs/:id/edit',
        component: BlogManageComponent
    },
    {
        path: 'cart',
        component: CartComponent,
        data: {
            title: 'Koшничка',
            metaDescription: 'Вашата кошничка на Непокор.мк. Прегледајте ги избраните производи и продолжете со наплатата за завршување на купувањето.',
            canonicalUrl: '/cart'
        }
    },
    {
        path: 'checkout',
        component: CheckoutComponent,
        data: {
            title: 'Наплата',
            metaDescription: 'Внесете ги вашите податоци за испорака на Непокор.мк. Завршете ја вашата нарачка со пополнување на информациите потребни за испорака.',
            canonicalUrl: '/checkout'
        }
    },
    {
        path: 'profile',
        component: ProfileComponent,
        data: {
            title: 'Профил',
            metaDescription: 'Вашиот кориснички профил на Непокор.мк.',
            canonicalUrl: '/profile'
        }
    },
    {
        path: 'contact-us',
        component: ContactUsComponent,
        data: {
            title: 'Контактирајте нè',
            metaDescription: 'Контактирајте го тимот на Непокор.мк за помош или информации. Пополнете го контакт формуларот или користете ги нашите информации за контакт за испратите порака.',
            canonicalUrl: '/contact-us'
        }
    },
    {
        path: "biznis-moznost",
        component: BrandAmbassadorComponent,
        data: {
            title: 'До Финансиска Слобода со AloeVeraGel.mk',
            metaDescription: 'Остварете финансиска слобода со AloeVeraGel.mk. Претставете ги висококвалитетните алое вера производи и станете дел од успешна глобална заедница.',
            canonicalUrl: '/biznis-moznost'
        }
    },
    {
        path: 'admin-panel',
        component: AdminPanelComponent,
        data: {
            title: 'Админ Панел',
            metaDescription: 'Админ панелот за управување со вашата продавница.',
            canonicalUrl: '/admin-panel'
        }
    },
    {
        path: 'admin-panel/email-marketing',
        component: EmailMarketingComponent
    },
    {
        path: '404',
        component: StatusPageComponent,
        data: {
            title: '404',
            message: 'Извинете, страницата што ја барате не постои.',
            style: 'text-red-600',
            buttonLabel: 'Назад кон почетната страница',
            buttonUrl: '/',
            canonicalUrl: '/404'
        }
    },
    {
        path: '403',
        component: StatusPageComponent,
        data: {
            title: '403',
            message: 'Немате овластување да пристапите до оваа страница.',
            style: 'text-red-600',
            buttonLabel: 'Контактирајте нè',
            buttonUrl: '/contact-us',
            canonicalUrl: '/403'
        }
    },
    {
        path: 'uspesna-naracka',
        component: StatusPageComponent,
        data: {
            title: 'Успешна нарачка!',
            message: 'Ти благодариме за нарачката!',
            style: 'text-green-600',
            buttonLabel: 'Назад кон почетната страница',
            buttonUrl: '/',
            canonicalUrl: '/order-success'
        }
    },
    {
        path: '**',
        redirectTo: '/404'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
