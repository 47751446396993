<div class="lg:overflow-x-scroll overflow-hidden bg-yellow-200">
    <div class="lg:w-fit grid-cols-2 sm:grid-cols-3 md:grid-cols-4 2xl:grid-cols-7 grid lg:flex lg:flex-row py-8 md:py-32 gap-4 lg:gap-8 px-2 lg:px-10 mx-auto items-center justify-center">
        @for (category of productCategories; track category) {
            <a [routerLink]="[category.link.route]" routerLinkActive="active"
               class="bg-white relative hover:underline mx-auto block max-w-[18rem] rounded-lg border-2 transform hover:scale-110 transition-transform shadow-lg pb-2 min-w-[10rem] lg:min-w-[18rem]">
                <div class="relative items-center">
                    <app-image class="h-36 lg:h-72 p-1 rounded-t-lg object-cover mx-auto" [src]="category.image"></app-image>
                </div>
                <div class="relative px-2 lg:pb-3 text-center text-sm lg:text-xl font-medium text-black">
                    <app-text [value]="category.link.text"></app-text>
                </div>
            </a>
        }
    </div>
</div>