import {Component, HostListener, OnInit} from '@angular/core';
import {Product} from "../../models/product";
import {ShopService} from "../../modules/shop/service/shop.service";
import {Router} from "@angular/router";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Carousel} from "tw-elements";
import {goToUrl} from "../../services/util/util";

@Component({
    selector: 'app-top-products',
    templateUrl: './top-products.component.html',
    styleUrls: ['./top-products.component.css'],
    animations: [
        trigger('slideInFromBottom', [
            state('hidden', style({
                transform: 'translateY(200%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateY(0)',
                opacity: 1
            })),
            transition('hidden <=> visible', animate('1.1s')),
        ])]
})
export class TopProductsComponent implements OnInit {

    products: Product[] = [];

    selectedProduct!: Product;

    showAnimation: boolean = false;

    constructor(
        private readonly shopService: ShopService,
        protected readonly router: Router
    ) {
    }

    ngOnInit(): void {
        this.getTopSellingProducts();
    }

    setSelectedProduct(product: Product) {
        this.selectedProduct = product;
        const orderDialogCarousel = new Carousel(document.getElementById("order-dialog-carousel"));
        orderDialogCarousel.to(0);
    }

    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const componentElement = document.getElementById('topProductsComponent');
        if (componentElement) {
            const rect = componentElement.getBoundingClientRect();
            this.showAnimation = rect.top <= window.innerHeight && rect.bottom >= 0;
        }
    }

    getTopSellingProducts(): void {
        this.shopService.getTopSellingProducts(4).subscribe(products => {
            this.products = products;
        });
    }

    protected readonly goToUrl = goToUrl;
}
