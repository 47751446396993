<div class="h-fit p-5 pt-[8.5rem] lg:pt-52 overflow-auto">
    <div class="py-3 md:mt-10 lg:grid grid-cols-6 text-center lg:w-4/5 mx-auto font-medium lg:space-x-5 space-y-2 md:space-y-0">
        @for (link of links; track link; let i = $index) {
            <app-secondary-button
                    @slideDown [value]="link.text"
                    (click)="setActiveTable(link.route)"></app-secondary-button>
        }
    </div>
    @if (auth.user$ | async; as user) {
        @if (user.email === environment.admin) {
            @switch (activeTable) {
                @case ("orders") {
                    <app-text [value]="'Вкупно нарачки: ' + pageInfo.totalElements"></app-text>
                    <app-table [rows]="orders" [columns]="orderColumns"></app-table>
                    @if (totalPages !== 0) {
                        <app-pagination
                                [pageNumber]="pageNumber"
                                [totalPages]="totalPages"
                                (pageChange)="onPageChange($event)">
                        </app-pagination>
                    }
                }
                @case ("products") {
                    <div class="lg:flex justify-center lg:justify-between items-center w-full mx-auto lg:mx-0 lg:flex-row-reverse">
                        <app-primary-button
                                class="pt-5 pb-5"
                                [value]="'Додади нов продукт'"
                                (click)="goToUrl(router, '/shop/product/add')">
                        </app-primary-button>
                        <app-text [value]="'Вкупно производи: ' + pageInfo.totalElements"></app-text>
                    </div>
                    <app-table
                            [rows]="products"
                            [columns]="productColumns"
                            (rowClicked)="onProductRowClick($event)"
                    ></app-table>
                    @if (totalPages !== 0) {
                        <app-pagination
                                [pageNumber]="pageNumber"
                                [totalPages]="totalPages"
                                (pageChange)="onPageChange($event)">
                        </app-pagination>
                    }
                }
                @case ("customers") {
                    <div class="lg:flex justify-center lg:justify-between items-center w-full mx-auto lg:mx-0 lg:flex-row-reverse">
                        <app-primary-button
                                class="pt-5 pb-5"
                                [value]="'Прати промотивен емаил'"
                                (click)="goToUrl(router, '/admin-panel/email-marketing')">
                        </app-primary-button>
                        <app-text [value]="'Вкупно потрошувачи: ' + pageInfo.totalElements"></app-text>
                    </div>
                    <app-table [rows]="customers" [columns]="customerColumns"></app-table>
                    @if (totalPages !== 0) {
                        <app-pagination
                                [pageNumber]="pageNumber"
                                [totalPages]="totalPages"
                                (pageChange)="onPageChange($event)">
                        </app-pagination>
                    }
                }
                @case ("discounts") {
                    <div class="lg:flex justify-center lg:justify-between items-center w-full mx-auto lg:mx-0 lg:flex-row-reverse">
                        <app-primary-button
                                class="pt-5 pb-5"
                                [value]="'Додади нов попуст'"
                                (click)="goToUrl(router, '/shop/product/discount/add')">
                        </app-primary-button>
                        <app-text [value]="'Вкупно попусти: ' + pageInfo.totalElements"></app-text>
                    </div>
                    <app-table
                            [rows]="discounts"
                            [columns]="discountColumns"
                            (rowClicked)="onDiscountRowClick($event)"
                    ></app-table>
                }
                @case ("ambassadors") {
                    <app-text [value]="'Вкупно бренд амбасадори: ' + pageInfo.totalElements"></app-text>
                    <app-table [rows]="customers" [columns]="customerColumns"></app-table>
                }
                @case ("blogs") {
                    <div class="lg:flex justify-center lg:justify-between items-center w-full mx-auto lg:mx-0 lg:flex-row-reverse">
                        <app-primary-button
                                class="pt-5 pb-5"
                                [value]="'Додади нов блог пост'"
                                (click)="goToUrl(router, '/blogs/add')">
                        </app-primary-button>
                        <app-text [value]="'Вкупно блог постови: ' + pageInfo.totalElements"></app-text>
                    </div>
                    <app-table
                            [rows]="blogs"
                            [columns]="blogColumns"
                            (rowClicked)="onBlogRowClick($event)"></app-table>
                }
            }
        } @else {
            <p class="pt-56">unauthorised</p>
        }
    }
</div>