<div class="p-0 lg:p-16 pt-[8.5rem] lg:pt-52">
    @if (auth.user$ | async; as user) {
        @if (user.email === environment.admin) {
            <form [formGroup]="discountControl" class="grid lg:grid-cols-2 relative mb-5 md:mb-0">
                <div class="pt-3 lg:pt-8 px-10 relative">
                    <div>
                        <app-text [value]="'Име'"></app-text>
                        <app-text-input
                                [placeholder]="'Внеси име'"
                                [control]="nameControl"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Процент на попуст'"></app-text>
                        <app-text-input
                                [placeholder]="'Внеси процент на попуст'"
                                [control]="discountPercentageControl"></app-text-input>

                    </div>
                    <div>
                        <app-text [value]="'Активен'"></app-text>
                        <app-checkbox
                                [label]="'Дали е активен'"
                                (checkboxChange)="handleCheckboxChange($event)"
                        ></app-checkbox>
                    </div>
                    <div>
                        <app-text [value]="'Почетен датум (пример: 2024-04-07T14:29:48)'"></app-text>
                        <app-text-input [placeholder]="'Внеси почетен датум'"
                                        [control]="startDateControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Завршен датум'"></app-text>
                        <app-text-input [placeholder]="'Внеси завршен датум'"
                                        [control]="endDateControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Код за попуст'"></app-text>
                        <app-text-input [placeholder]="'Внеси код за попуст'"
                                        [control]="discountCodeControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                </div>
            </form>
            <div class="flex flex-shrink-0 flex-wrap items-center justify-center gap-x-3 p-5">
                @if (isEditMode) {
                    <app-secondary-button
                            class="bg-red-600"
                            [value]="'Избриши го продуктот'"
                            (click)="openConfirmationModal()"/>
                    <app-confirmation-modal (confirmationEvent)="deleteDiscount()"></app-confirmation-modal>

                    <!--                    <app-primary-button [value]="'Промени ги податоците'"-->
                            <!--                                        (click)="updateDiscount()"></app-primary-button>-->
                } @else {
                    <app-primary-button [value]="'Додади нов попуст'"
                                        (click)="addDiscount()"></app-primary-button>
                }

            </div>
        }
    } @else {
        <p class="pt-56">unauthorised</p>
    }
</div>