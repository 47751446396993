<div class="p-0 lg:p-16 pt-[8.5rem] lg:pt-52">
    @if (auth.user$ | async; as user) {
        @if (user.email === environment.admin) {
            <div class="grid relative mb-5 md:mb-0">
                <form [formGroup]="emailControl"
                      class="pt-3 lg:pt-8 px-10 relative">
                    <div>
                        <app-text [value]="'Наслов'"></app-text>
                        <app-text-input
                                [placeholder]="'Внеси наслов'"
                                [control]="titleControl"
                                [type]="'required'"></app-text-input>

                    </div>
                    <div>
                        <app-text [value]="'Порака'"></app-text>
                        <app-text-input
                                [placeholder]="'Внеси: бренд-амбасадор - за да пратиш понуда за бренд амбасадор на сите корисници во системот'"
                                [control]="messageControl"
                                [type]="'required'"
                        ></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Текст на копчето'"></app-text>
                        <app-text-input [placeholder]="'Внеси текст на копчето'"
                                        [control]="buttonTextControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                    <div>
                        <app-text [value]="'Линк на копчето'"></app-text>
                        <app-text-input [placeholder]="'Внеси линк на копчето (внеси / за да пратиш маил без копче)'"
                                        [control]="buttonLinkControl"
                                        [type]="'required'"></app-text-input>
                    </div>
                </form>
            </div>
            <div class="flex flex-shrink-0 flex-wrap items-center justify-center gap-x-3 p-5">
                <app-primary-button
                        [value]="'Испрати емаил на сите потрошувачи'"
                        (click)="openConfirmationModal()"
                        [isLoading]="isLoading"/>
                <app-confirmation-modal (confirmationEvent)="sendEmail()"></app-confirmation-modal>
                <app-secondary-button
                        class="bg-red-600"
                        [value]="'Испрати тест емаил'"
                        (click)="sendEmail(true)"
                        [isLoading]="isLoading"/>
            </div>
        }
    } @else {
        <p class="pt-56">unauthorised</p>
    }
</div>