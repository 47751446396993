@if (products.length > 0) {
    <div id="topProductsComponent" class="py-12 md:py-28 px-3 lg:px-10">
        <app-order-dialog [selectedProduct]="selectedProduct"></app-order-dialog>
        <div [@slideInFromBottom]="showAnimation ? 'visible' : 'hidden'">
            <app-text [class]="'text-center text-3xl md:text-5xl font-bold pb-1'"
                      [value]="'Најпродавани производи'"></app-text>
        </div>
        <div class="grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-5 text-center">
            @for (product of products; track product) {
                <app-card class="md:pt-5" [product]="product" (click)="setSelectedProduct(product)"></app-card>
            }
        </div>
        <div class="mx-auto md:w-1/2 pt-10">
            <app-secondary-button
                    (click)="goToUrl(router, '/shop')" class="flex w-2/3 md:w-80 mx-auto"
                    [value]="'Види ги сите производи'"></app-secondary-button>
        </div>
    </div>
}