import {Component, Inject, OnInit} from '@angular/core';
import {ShopService} from "../../service/shop.service";
import {Product} from "../../../../models/product";
import {Observable, switchMap, take} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {CartService} from "../../../cart/service/cart.service";
import {Carousel, initTE, Sidenav, Toast} from "tw-elements";
import {OrderItem} from "../../../../models/order-item";
import {Link} from "../../../../models/link";
import {environment} from "../../../../environments/environment.production";
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from "@angular/common";
import {setCanonicalUrl} from "../../../../services/util/util";
import {FBQ} from "../../../../models/fbq";

declare let fbq: FBQ;

@Component({
    selector: 'app-product-page',
    templateUrl: './product-page.component.html',
    styleUrl: './product-page.component.css'
})
export class ProductPageComponent implements OnInit {

    product!: Observable<Product>;

    products: Product[] = [];

    orderItemControl: UntypedFormGroup = new UntypedFormGroup({
        quantity: new UntypedFormControl('', [Validators.required]),
    });

    toastMessage: string = '';

    links: Link[] = [
        {
            text: "Почетна",
            route: environment.navigation.home,
        },
        {
            text: "Продавница",
            route: environment.navigation.shop,
        }]

    constructor(private route: ActivatedRoute,
                private readonly router: Router,
                private shopService: ShopService,
                private cartService: CartService,
                private meta: Meta,
                private titleService: Title,
                @Inject(DOCUMENT) private document: Document) {
    }

    ngOnInit(): void {
        this.product = this.route.paramMap.pipe(
            switchMap(params => {
                const slugAndId = params.get('slugAndId')!;

                let id: number;

                if (slugAndId.includes('-')) {
                    id = +slugAndId.split('-').pop()!;
                } else {
                    id = +slugAndId;
                }
                return this.shopService.getProductById(id);
            })
        );

        this.product.subscribe(product => {
            this.updateLinks(product.category);
            const canonicalUrl = environment.domain.frontend + environment.navigation.shop + '/product/' + (product?.slug ? product?.slug + '-' + product?.id : product?.id);
            setCanonicalUrl(canonicalUrl);
            this.setTitle(product);
            this.setMetaDescription(product);
            this.setStructuredData(product);
            this.setMetaKeywords(product);
            this.shopService.getProductsByIds(product.productAdIds).subscribe((products) => this.products = products);
            fbq('track', 'ViewContent', {
                content_name: product?.name,
                content_ids: [product.id],
                content_category: product?.category,
                value: product?.price,
                currency: 'MKD',
                content_type: 'product'
            });
            setTimeout(() => initTE({Carousel}), 500);
        });
    }

    setStructuredData(product: Product) {
        const script = this.document.createElement('script');
        const productImage = environment.domain.frontend + "/assets/images/" + product?.category.toLowerCase()?.replace('_', '-') + '/' + (product?.images?.[0] ?? '') + '.webp';

        script.type = 'application/ld+json';

        const jsonLd = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": product.name,
            "image": productImage,
            "description": product.description ?? '',
            "sku": product.designID ?? '',
            "brand": {
                "@type": "Brand",
                "name": "Алое вера гел"
            },
            "offers": {
                "@type": "Offer",
                "priceCurrency": "MKD",
                "price": product.price ?? '',
                "itemCondition": "https://schema.org/NewCondition",
                "availability": "https://schema.org/InStock",
                "url": window.location.href
            }
        };
        script.text = JSON.stringify(jsonLd);
        this.document.head.appendChild(script);
    }

    get quantityControl(): UntypedFormControl {
        return this.orderItemControl.get('quantity') as UntypedFormControl;
    }

    addToCart() {
        this.createOrderItem().then(() => {
            const cartOverview = new Sidenav(document.getElementById("cart-overview"));
            cartOverview.show();
        }).catch(error => {
            this.openToast('Failed to add item to cart');
            console.error(error);
        });
    }

    buyNow() {
        this.createOrderItem().then(() => {
            this.router.navigateByUrl('/checkout');
        }).catch(error => {
            this.openToast('Failed to proceed to checkout');
            console.error(error);
        });
    }

    private createOrderItem(): Promise<OrderItem> {
        return new Promise((resolve, reject) => {
            this.product.pipe(take(1)).subscribe({
                next: (product) => {
                    const orderItem: OrderItem = {
                        product: product,
                        quantity: this.quantityControl.value
                    };

                    this.cartService.updateCartItems([...this.cartService.retrieveItemsFromLocalStorage(), orderItem]);
                    this.updateLocalStorage(orderItem);
                    resolve(orderItem);
                    fbq('track', 'AddToCart', {
                        content_name: product?.name,
                        contents: {
                            id: product.id,
                            quantity: this.quantityControl.value,
                        },
                        content_category: product?.category,
                        value: product?.price,
                        currency: 'MKD',
                        content_type: 'product'
                    });
                },
                error: (error) => reject(error)
            });
        });
    }

    private updateLocalStorage(orderItem: OrderItem): void {
        const existingItems = this.getExistingItems();
        existingItems.push(orderItem);
        localStorage.setItem('cartItems', JSON.stringify(existingItems));
    }

    private getExistingItems(): OrderItem[] {
        const existingItemsJson = localStorage.getItem('cartItems');
        return existingItemsJson ? JSON.parse(existingItemsJson) : [];
    }

    setTitle(product: Product) {
        this.titleService.setTitle(product.name + " | Алое вера гел");
    }

    updateLinks(category: string) {
        if (category) {
            const newLink: Link = {
                text: this.setProductCategory(category),
                route: `${environment.navigation.shop}/${category.replaceAll("_", "-").toLowerCase()}`
            };

            const linkExists = this.links.some(link => link.route === newLink.route);

            if (!linkExists) {
                this.links.push(newLink);
            }
        }
    }

    setMetaDescription(product: Product) {
        this.meta.updateTag({name: 'description', content: product.metaDescription});
    }

    private setMetaKeywords(product: Product): void {
        const keywords = product.keywords?.join(', ') ?? '';

        this.meta.updateTag({name: 'keywords', content: keywords});
    }

    openToast(message: string) {
        const toast = new Toast(document.getElementById("toast"));
        this.toastMessage = message;
        toast.show();
    }

    setProductCategory(category: string | undefined) {
        switch (category) {
            case 'ALOE_VERA_PIJALOCI':
                return "Алое Вера Пијалоци"
            case 'PCELNI_PROIZVODI':
                return "Пчелни Производи"
            case 'DODATOCI_VO_ISHRANA':
                return "Додатоци во исхрана"
            case 'LICNA_NEGA':
                return "Лична нега"
            case 'NEGA_ZA_LICE':
                return "Нега за лице"
            case 'ETERICNI_MASLA':
                return "Етерични масла"
            case 'NEGA_ZA_TELO':
                return "Нега за тело"
            case 'KONTROLA_NA_TELESNA_TEZINA':
                return "Контрола на телесната тежина"
            default :
                return ""
        }
    }

}