<div class="h-fit p-2 pb-16 sm:p-6 lg:p-16 pt-[8.5rem] lg:pt-52">
    <app-order-dialog [selectedProduct]="selectedProduct"></app-order-dialog>
    <div @slideDown>
        <app-text class="text-center text-4xl lg:text-7xl font-black"
                  [isTitle]="true"
                  [value]="pageTitle.toUpperCase()"></app-text>
    </div>
    <div class="pt-3 md:mt-10 lg:grid hidden md:grid-cols-8 text-center lg:w-11/12 mx-auto font-medium md:space-x-5 space-y-2 md:space-y-0">
        @for (link of links; track link; let i = $index) {
            <div>
                <app-secondary-button
                        @slideDown [value]="link.text"
                        (click)="goToUrl(router, link.route)"></app-secondary-button>
            </div>
        }
    </div>
    <div class="pt-3 sm:pt-10 lg:w-11/12 mx-auto grid gap-2 sm:gap-8 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4"
         [ngClass]="products.length === 0 ? 'h-screen' : ''">
        @for (product of products; track product) {
            <div>
                <app-card @slideDown [product]="product" (click)="setSelectedProduct(product)"></app-card>
            </div>
        }
    </div>
    @if (totalPages !== 0) {
        <app-pagination
                [pageNumber]="pageNumber"
                [totalPages]="totalPages"
                (pageChange)="onPageChange($event)">
        </app-pagination>
    }
</div>
<app-footer></app-footer>