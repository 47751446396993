import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {Link} from "../../models/link";
import {environment} from "../../environments/environment";
import {animate, animateChild, group, query, stagger, state, style, transition, trigger} from "@angular/animations";
import {CartService} from "../../modules/cart/service/cart.service";
import {initTE, Sidenav} from "tw-elements";


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    animations: [
        trigger('slideIn', [
            state('hidden', style({
                transform: 'translateY(-80%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateY(0)',
                opacity: 1
            })),
            transition('visible <=> hidden', group([
                query('@slideLeft',
                    stagger('0.25s', [animateChild()])
                ),
                animate('0.3s'),
                query('@slideLeftFast',
                    stagger('0.15s', [animateChild()])
                ),
                animate('0.3s'),
            ]))
        ]),
        trigger('slideLeft', [
            state('hidden', style({
                transform: 'translateX(-80%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateX(0)',
                opacity: 1
            })),
            transition('hidden <=> visible', animate('0.5s')),
        ]),
        trigger('slideLeftFast', [
            state('hidden', style({
                transform: 'translateX(-80%)',
                opacity: 0
            })),
            state('visible', style({
                transform: 'translateX(0)',
                opacity: 1
            })),
            transition('hidden <=> visible', animate('0.5s')),
        ]),
        trigger('slideWrapper', [
            transition(':enter', group([
                query('@slideDown',
                    stagger('0.25s', [animateChild()]),
                    {optional: true}
                )]))
        ]),
        trigger('slideDown', [
            transition(':enter', [
                style({transform: 'translateY(-80%)', opacity: 0}),
                animate('0.5s')
            ])

        ])

    ]
})
export class HeaderComponent implements OnInit {

    showMenu: boolean = false;

    showSearchOverview: boolean = false;

    showMusicPlayer: boolean = false;

    screenWidth!: number;

    showInfoBanner: boolean = true;

    languages: Link[] = [
        {
            text: "Mk",
            route: "?locale=mk"
        },
        {
            text: "Gb",
            route: "?locale=en"
        }
    ]

    links: Link[] = [
        {
            text: "Почетна",
            route: environment.navigation.home,
        },
        {
            text: "Продавница",
            route: environment.navigation.shop,
        },
        {
            text: "Блог",
            route: environment.navigation.blog,
        },
        {
            text: "",
            route: environment.navigation.brandAmbassador,
        },
        {
            text: "За нас",
            route: environment.navigation.aboutUs,
        },
        {
            text: "Бизнис можност",
            route: environment.navigation.brandAmbassador,
        },
        {
            text: "Контакт",
            route: environment.navigation.contactUs,
        },
    ];

    shopLinks: Link[] = [
        {text: "Алое вера пијалоци", route: "shop/aloe-vera-pijaloci"},
        {text: "Пчелни производи", route: "shop/pcelni-proizvodi"},
        {text: "Додатоци во исхрана", route: "shop/dodatoci-vo-ishrana"},
        {text: "Лична нега", route: "shop/licna-nega"},
        {text: "Нега за лице", route: "shop/nega-za-lice"},
        {text: "Етерични масла", route: "shop/etericni-masla"},
        {text: "Нега за тело", route: "shop/nega-za-telo",},
        {text: "Контрола на телесната тежина", route: "shop/kontrola-na-telesna-tezina",},
    ];

    constructor(
        public readonly cartService: CartService,
        protected elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        initTE({Sidenav}, {allowReinits: true});
        this.setSideNavWidth();
    }

    toggleVisibility(): void {
        this.showMenu = !this.showMenu;
        this.setSideNavWidth();
    }

    goToShoppingCart() {
        const cartOverview = new Sidenav(document.getElementById("cart-overview"));
        cartOverview.show();
        const sidenav = new Sidenav(document.getElementById("sidenav"));
        sidenav.hide();
        this.closeMenu();
    }

    closeMenu(): void {
        this.showMenu = false;
    }

    toggleSearchOverview(): void {
        this.showSearchOverview = !this.showSearchOverview;
    }

    toggleMusicPlayer(): void {
        this.showMusicPlayer = !this.showMusicPlayer;
    }

    handleSearchOverviewChange(): void {
        this.showSearchOverview = false;
    }

    @HostListener('window:resize', ['$event'])
    setSideNavWidth() {
        this.screenWidth = this.elementRef.nativeElement.offsetWidth;
        const sidenavElement = document.getElementById("sidenav");
        if (sidenavElement) {
            sidenavElement.style.width = `${this.screenWidth}px`;
        }
    }

    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;
        this.showInfoBanner = scrollPosition === 0;
    }

}