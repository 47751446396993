import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Blog} from "../../../../models/blog";

@Injectable({
    providedIn: 'root'
})
export class BlogService {

    constructor(
        private readonly httpClient: HttpClient) {
    }

    getAllBlogs(): Observable<Blog[]> {
        const url: string = environment.domain.backend + environment.api.blogs;

        return this.httpClient.get<Blog[]>(url);
    }

    getBlogById(id: number): Observable<Blog> {
        const url: string =
            environment.domain.backend +
            environment.api.blogs +
            "/" + id;

        return this.httpClient.get<Blog>(url);
    }

    addBlog(blog: Blog): Observable<Blog> {
        const url: string = environment.domain.backend + environment.api.blogs;

        return this.httpClient.post<Blog>(url, blog);
    }

    updateBlog(blog: Blog): Observable<Blog> {
        const url: string = environment.domain.backend + environment.api.blogs + "/" + blog.id;

        return this.httpClient.put<Blog>(url, blog);
    }

    deleteBlog(blogId: number): Observable<void> {
        const url: string = environment.domain.backend + environment.api.blogs + "/" + blogId;

        return this.httpClient.delete<void>(url);
    }

    generateBlogContent(keyword: string, instruction: string): Observable<Blog> {
        const url: string = environment.domain.backend + environment.api.blogs + "/generate";

        const payload = {
            keyword,
            instruction
        };

        return this.httpClient.post<Blog>(url, payload);
    }

}
